// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/bg-guidance.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".model[data-v-de5ac696]{position:fixed;top:0;left:0;width:100%;height:100%;background:rgba(0,0,0,.5);z-index:999}.model .guidance[data-v-de5ac696]{position:absolute;top:2.666667vw;right:1.333333vw;width:64vw;height:27.733333vw;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100% 100%;background-repeat:no-repeat;background-position:50%}.model .guidance .title[data-v-de5ac696]{width:53.333333vw;height:11.733333vw;font-size:4.266667vw;font-family:PingFang SC-Regular,PingFang SC;font-weight:400;color:#181818;margin:0 auto;margin-top:13.333333vw}@media (orientation:landscape){.model .guidance[data-v-de5ac696]{top:1.760563vw;right:.880282vw;width:42.253521vw;height:18.309859vw}.model .guidance .title[data-v-de5ac696]{width:35.211268vw;height:7.746479vw;font-size:2.816901vw;margin-top:8.802817vw}}", ""]);
// Exports
module.exports = exports;
