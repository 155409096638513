



















import { Component, Vue } from 'vue-property-decorator'
@Component({
  name: 'SmsPush'
})
export default class SmsPush extends Vue {
  // 是否显示引导
  isShow = false

  // 点击下载
  handelDownload() {
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      // alert(navigator.userAgent)
      // 如果用户已经安装App直接跳转App
      window.location.href = 'allo.ios.app://'
      // 如果用户打开App失败，直接跳转下载
      setTimeout(function() {
        window.location.href = 'https://itunes.apple.com/app/1664635935'
      }, 3000)
    } else if (/(Android)/i.test(navigator.userAgent)) {
      // alert(navigator.userAgent)
      // 如果用户已经安装App直接跳转App
      window.location.href = 'allo://tel.top/home/callshow?select=0'
      // 如果用户打开App失败，直接跳转下载
      setTimeout(function() {
        window.location.href = process.env.VUE_APP_ALLO
      }, 3000)
    } else {
      window.location.href = process.env.VUE_APP_ALLO
    }
  }
}
